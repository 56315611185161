.logo-container img {
	width: 40%;
}

.template-example img {
	width: 100%;
}

label.required:after {
	content: '*';
	color: red;
}

.custom-box-shadow {
	box-shadow: 0px 0.5px 10px #e2e2e2;
}

#result {
	color: #fff;
	border-radius: 4px;
	padding: 3px;
	font-size: 10px;
	display: inline-block;
	margin-top: 5px;
}

#result short weak good strong {
}

#result.short {
	background-color: #5600ff;
}

#result.weak {
	background-color: #ff2f00;
}

#result.good {
	background-color: #f7ab00;
}

#result.strong {
	background-color: #3abd31;
}

@media all and (max-width: 767px) {
	.logo-container {
		text-align: center;
	}
}

@media all and (min-width: 643px) and (max-width: 659px) {
	#age {
		width: 729px;
	}
}
